import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Navbar() {
  const location = useLocation();

  return (
    <nav className="border-b border-gray-100 bg-white/80 backdrop-blur-md sticky top-0 z-50">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <Link 
            to="/" 
            className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-blue-600 hover:opacity-80 transition-opacity"
          >
            AIClickers
          </Link>
          
          <div className="flex items-center space-x-8">
            <Link 
              to="/" 
              className={`${
                location.pathname === '/' 
                  ? 'text-indigo-600'
                  : 'text-gray-600 hover:text-gray-900'
              } transition-colors relative font-medium`}
            >
              {location.pathname === '/' && (
                <span className="absolute -bottom-[21px] left-0 right-0 h-0.5 bg-indigo-600" />
              )}
              Home
            </Link>
            <Link 
              to="/blog"
              className={`${
                location.pathname === '/blog'
                  ? 'text-indigo-600'
                  : 'text-gray-600 hover:text-gray-900'
              } transition-colors relative font-medium`}
            >
              {location.pathname === '/blog' && (
                <span className="absolute -bottom-[21px] left-0 right-0 h-0.5 bg-indigo-600" />
              )}
              Blog
            </Link>
            <Link 
              to="/about"
              className={`${
                location.pathname === '/about'
                  ? 'text-indigo-600'
                  : 'text-gray-600 hover:text-gray-900'
              } transition-colors relative font-medium`}
            >
              {location.pathname === '/about' && (
                <span className="absolute -bottom-[21px] left-0 right-0 h-0.5 bg-indigo-600" />
              )}
              About
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
// protectionConfig.js - Central configuration file
export const protectionConfig = {
    // Feature flags
    disableRightClick: true,
    disableKeyboardCopy: true,
    disablePrinting: true,
    disableViewSource: true,
    // Enable in specific environments
    enableInProduction: true,
    // Excluded paths/routes
    excludedRoutes: ['/terms', '/privacy'],
    // Excluded elements
    excludedSelectors: ['input', 'textarea', '.allow-copy']
  };
import React from 'react';

const BlogCard = ({ 
  title = '', 
  description = '', 
  date = '', 
  author = '', 
  tags = []
}) => {
  // Ensure tags is always an array
  const safeTags = Array.isArray(tags) ? tags : [];

  return (
    <div className="h-full p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
      <h2 className="text-xl font-semibold text-gray-900 mb-3 line-clamp-2">
        {title}
      </h2>
      <p className="text-gray-600 text-sm mb-4 line-clamp-3">
        {description}
      </p>
      <div className="mt-auto">
        <div className="flex items-center text-sm text-gray-500 mb-4">
          <span>{author}</span>
          {author && date && <span className="mx-2">•</span>}
          <span>{date}</span>
        </div>
        {safeTags.length > 0 && (
          <div className="flex flex-wrap gap-2">
            {safeTags.map((tag, index) => (
              <span 
                key={index}
                className="px-3 py-1 rounded-full text-sm bg-blue-100 text-blue-800"
              >
                {tag}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogCard;
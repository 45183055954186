import React from 'react';
import { Link } from 'react-router-dom';

const AboutCard = ({ title, description, emoji }) => (
  <div className="bg-white p-8 rounded-2xl shadow-sm hover:shadow-md transition-all border border-gray-100">
    <div className="w-12 h-12 rounded-xl flex items-center justify-center bg-blue-100 text-2xl mb-6">
      {emoji}
    </div>
    <h3 className="text-xl font-semibold mb-4">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

function About() {
  const values = [
    {
      title: "Innovation",
      description: "We stay at the forefront of AI technology, bringing you the latest developments and practical applications.",
      emoji: "💡"
    },
    {
      title: "Accessibility",
      description: "Complex concepts are broken down into clear, actionable insights for all skill levels.",
      emoji: "🎯"
    },
    {
      title: "Community",
      description: "We foster a supportive environment for learning and sharing AI knowledge and experiences.",
      emoji: "🤝"
    }
  ];

  const team = [
    {
      title: "Expert Contributors",
      description: "Our content is created by AI practitioners and industry experts with real-world experience.",
      emoji: "👩‍💻"
    },
    {
      title: "Technical Writers",
      description: "Skilled communicators who ensure our content is clear, accurate, and engaging.",
      emoji: "✍️"
    },
    {
      title: "Community Managers",
      description: "Dedicated team members who foster meaningful discussions and knowledge sharing.",
      emoji: "💫"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Hero Section - Reduced bottom padding */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-grid-gray-100/50 bg-[size:20px_20px]" />
        <div className="container mx-auto px-4 py-16 relative"> {/* Reduced from py-24 to py-16 */}
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-4xl font-bold text-gray-900 mb-6">
              About AIClickers
            </h1>
            <p className="text-xl text-gray-600 leading-relaxed">
              Your gateway to understanding and implementing Artificial Intelligence. 
              We break down complex AI concepts into accessible, practical knowledge for professionals, 
              businesses, and technology enthusiasts.
            </p>
          </div>
        </div>
      </div>

      {/* Mission Section - Adjusted spacing */}
      <div className="container mx-auto px-4 py-12"> {/* Reduced from py-16 to py-12 */}
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-4"> {/* Reduced from mb-6 */}
            Our Mission
          </h2>
          <p className="text-xl text-gray-600 leading-relaxed">
            To demystify AI technology and empower individuals and businesses to harness 
            its potential for innovation and growth. We believe in making AI accessible
            to everyone, regardless of their technical background.
          </p>
        </div>
      </div>

      {/* Values Section - Adjusted spacing */}
      <div className="container mx-auto px-4 py-12"> {/* Reduced from py-16 to py-12 */}
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center"> {/* Reduced from mb-12 */}
            Our Values
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {values.map((value) => (
              <AboutCard key={value.title} {...value} />
            ))}
          </div>
        </div>
      </div>

      {/* Team Section - Adjusted spacing */}
      <div className="container mx-auto px-4 py-12"> {/* Reduced from py-16 to py-12 */}
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center"> {/* Reduced from mb-12 */}
            Our Team
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {team.map((member) => (
              <AboutCard key={member.title} {...member} />
            ))}
          </div>
        </div>
      </div>

      {/* Call to Action - Adjusted spacing */}
      <div className="container mx-auto px-4 py-12"> {/* Reduced from py-16 to py-12 */}
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-4"> {/* Reduced from mb-6 */}
            Join Our Community
          </h2>
          <p className="text-xl text-gray-600 mb-6"> {/* Reduced from mb-8 */}
            Start your AI journey with us today and be part of a growing community of AI enthusiasts and professionals.
          </p>
          <Link
            to="/blog"
            className="inline-flex items-center gap-2 bg-blue-600 text-white px-8 py-4 rounded-xl hover:bg-blue-700 transition-all"
          >
            Explore Our Content
            <span className="group-hover:translate-x-1 transition-transform">→</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default About;
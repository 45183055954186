import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { blogApi } from '../services/api';
import BlogCard from './BlogCard';

function Blog() {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const observerRef = useRef();
  const initialFetchDone = useRef(false);

  const fetchPosts = async (pageNumber) => {
    if (loading) return;
    
    try {
      setLoading(true);
      const response = await blogApi.getAllPosts({
        page: pageNumber,
        limit: 6
      });
      
      setPosts(prevPosts => [...prevPosts, ...response.data]);
      setHasMore(response.meta.has_more);
      setPage(response.meta.current_page);
    } catch (err) {
      console.error('Error fetching posts:', err);
      setError('Failed to load blog posts. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const lastPostRef = useCallback((node) => {
    if (loading) return;

    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore && initialFetchDone.current) {
        fetchPosts(page + 1);
      }
    }, {
      rootMargin: '100px'
    });

    if (node) observerRef.current.observe(node);
  }, [loading, hasMore, page]);

  useEffect(() => {
    if (!initialFetchDone.current) {
      initialFetchDone.current = true;
      fetchPosts(1);
    }
    
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  const handlePostClick = (id) => {
    navigate(`/blog/${id}`);
  };

  const handleRetry = () => {
    setError(null);
    setPosts([]);
    setPage(1);
    setHasMore(true);
    initialFetchDone.current = false;
    fetchPosts(1);
  };

  // Updated skeleton loader for text-only design
  const renderSkeleton = () => (
    <div className="animate-pulse p-6 bg-white rounded-lg shadow-md">
      <div className="h-6 bg-gray-200 rounded w-3/4 mb-4"/>
      <div className="space-y-2 mb-4">
        <div className="h-4 bg-gray-200 rounded w-full"/>
        <div className="h-4 bg-gray-200 rounded w-5/6"/>
        <div className="h-4 bg-gray-200 rounded w-4/6"/>
      </div>
      <div className="flex items-center space-x-2 mb-4">
        <div className="h-4 w-20 bg-gray-200 rounded"/>
        <div className="h-4 w-4 bg-gray-200 rounded"/>
        <div className="h-4 w-24 bg-gray-200 rounded"/>
      </div>
      <div className="flex gap-2">
        <div className="h-6 w-16 bg-gray-200 rounded-full"/>
        <div className="h-6 w-16 bg-gray-200 rounded-full"/>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-16">
        {/* Header Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Tech Blog
          </h1>
          <p className="text-xl text-gray-600">
            Latest insights, tutorials, and updates in AI technology
          </p>
        </div>

        {/* Error State */}
        {error && (
          <div className="text-center text-red-600 mb-8">
            {error}
            <button 
              onClick={handleRetry}
              className="ml-4 text-blue-600 hover:underline"
            >
              Try Again
            </button>
          </div>
        )}

        {/* Blog Posts Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
          {posts.map((post, index) => (
            <div
              key={post.id}
              ref={index === posts.length - 1 ? lastPostRef : null}
              onClick={() => handlePostClick(post.id)}
              className="cursor-pointer h-full"
            >
              <BlogCard
                title={post.title}
                description={post.meta_description}
                author={post.author}
                date={new Date(post.created_at).toLocaleDateString()}
                tags={post.tags}
              />
            </div>
          ))}

          {/* Loading State */}
          {loading && (
            <>
              {[1, 2, 3].map((n) => (
                <div key={n}>{renderSkeleton()}</div>
              ))}
            </>
          )}
        </div>

        {/* Loading more indicator */}
        {loading && page > 1 && (
          <div className="text-center text-gray-600 mt-8">
            Loading more posts...
          </div>
        )}

        {/* End of content indicator */}
        {!hasMore && posts.length > 0 && (
          <div className="text-center text-gray-600 mt-8">
            You've reached the end of the posts
          </div>
        )}
      </div>
    </div>
  );
}

export default Blog;
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { blogApi } from '../../services/api';

function BlogPost() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPost();
  }, [id]);

  const fetchPost = async () => {
    try {
      setLoading(true);
      const postData = await blogApi.getPostById(id);
      
      if (!postData) {
        throw new Error('Post not found');
      }
      
      setPost(postData);
    } catch (err) {
      console.error('Error fetching post:', err);
      setError('Failed to load blog post. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const getTagsArray = (tagsString) => {
    return tagsString ? tagsString.split(',').map(tag => tag.trim()) : [];
  };

  const renderSection = (section) => {
    const { content_type, content, style_config, language } = section;

    switch (content_type) {
      case 'header':
        const HeaderTag = style_config?.size || 'h2';
        return (
          <HeaderTag
            style={{
              color: style_config?.color,
              marginTop: style_config?.margin?.top,
              marginBottom: style_config?.margin?.bottom,
              fontWeight: style_config?.font_weight,
              textAlign: style_config?.alignment,
            }}
            className="font-bold"
          >
            {content}
          </HeaderTag>
        );

      case 'text':
        return (
          <div
            style={{
              color: style_config?.color,
              fontSize: style_config?.fontSize,
              lineHeight: style_config?.lineHeight,
              marginBottom: style_config?.margin?.bottom,
            }}
            className="whitespace-pre-line"
          >
            {content}
          </div>
        );

      case 'code':
        return (
          <div className="my-4">
            <pre 
              className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto"
              style={{
                fontSize: style_config?.fontSize || '14px',
              }}
            >
              <code className={`language-${language || 'javascript'}`}>
                {content}
              </code>
            </pre>
          </div>
        );

      default:
        return null;
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <div className="container mx-auto px-4 py-16">
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50">
        <div className="container mx-auto px-4 py-16">
          <div className="text-center text-red-600">
            <p>{error}</p>
            <button 
              onClick={() => navigate('/blog')}
              className="mt-4 inline-block bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700"
            >
              Return to Blog
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (!post) return null;

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-16 max-w-4xl">
        {/* Back button */}
        <button
          onClick={() => navigate('/blog')}
          className="mb-8 inline-flex items-center text-blue-600 hover:text-blue-800"
        >
          <svg
            className="w-5 h-5 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
          Back to Blog
        </button>

        {/* Article content */}
        <article className="bg-white rounded-lg shadow-lg">
          <div className="p-8">
            {/* Category and Tags Header */}
            <div className="border-b border-gray-200 pb-6 mb-6">
              <div className="flex items-center text-sm text-gray-500 mb-4">
                {post.category && (
                  <span className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full">
                    {post.category}
                  </span>
                )}
              </div>

              <div className="flex flex-wrap gap-2">
                {getTagsArray(post.tags).map(tag => (
                  <span 
                    key={tag}
                    className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-medium text-gray-700"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>

            {/* Content */}
            <div className="prose prose-lg max-w-none">
              {post.sections?.sort((a, b) => a.section_order - b.section_order).map((section, index) => (
                <div key={`${section.content_type}-${section.section_order}`}>
                  {renderSection(section)}
                </div>
              ))}
            </div>
          </div>
        </article>
      </div>
    </div>
  );
}

export default BlogPost;
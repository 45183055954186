// src/App.jsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/common/Navbar';
import BlogPost from './components/blog/BlogPost';
import Home from './pages/Home';
import Blog from './pages/Blog';
import About from './pages/About';
import AIBasics from './pages/AIBasics';
// import TypesOfAI from './components/ai-basics/TypesOfAI';
// import TopicDetail from './pages/TopicDetail';
// import DetailedTopicPage from './components/ai-basics/DetailedTopicPage';
// import IntroductionToAI from './components/ai-basics/IntroductionToAI';
// import AIFundamentals from './components/ai-basics/AIFundamentals';
import MachineLearning from './pages/MachineLearning';
import DeepLearning from './pages/DeepLearning';
import { useContentProtection } from './utils/useContentProtection';
import { HelmetProvider } from 'react-helmet-async';


function App() {
  useContentProtection();
  return (
    <HelmetProvider>
      <Router>
        <div className="min-h-screen bg-gray-50">
          <Navbar />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:id" element={<BlogPost />} />
              <Route path="/about" element={<About />} />
              <Route path="/ai-basics" element={<AIBasics />} />
              <Route path="/machine-learning" element={<MachineLearning />} />
              <Route path="/deep-learning" element={<DeepLearning />} />

              {/* AI Topics Routes
              <Route path="/ai-basics/topics/introduction-to-ai" element={<IntroductionToAI />} />
              <Route path="/ai-basics/topics/introduction-to-ai/ai-fundamentals" element={<AIFundamentals />} /> */}

            </Routes>
          </main>
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
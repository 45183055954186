import React from 'react';

const TopicCard = ({ title, description, difficulty }) => (
  <div className="bg-white rounded-2xl p-8 hover:shadow-lg transition-all duration-300 border border-gray-100">
    <div className={`inline-block px-3 py-1 rounded-full text-sm font-medium mb-4 
      ${difficulty === 'Beginner' ? 'bg-green-50 text-green-600' : 
        difficulty === 'Intermediate' ? 'bg-blue-50 text-blue-600' :
        'bg-purple-50 text-purple-600'}`}
    >
      {difficulty}
    </div>
    <h3 className="text-xl font-semibold mb-3">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const AIBasics = () => {
  const topics = [
    {
      title: "Introduction to AI",
      description: "Learn the fundamental concepts of artificial intelligence, its history, and core principles.",
      difficulty: "Beginner"
    },
    {
      title: "Types of AI Systems",
      description: "Explore different types of AI systems including rule-based, expert systems, and modern approaches.",
      difficulty: "Beginner"
    },
    {
      title: "AI Problem Solving",
      description: "Understanding how AI approaches problem-solving through search algorithms and heuristics.",
      difficulty: "Intermediate"
    },
    {
      title: "Knowledge Representation",
      description: "Learn how AI systems represent and manipulate knowledge to make decisions.",
      difficulty: "Intermediate"
    },
    {
      title: "Ethics in AI",
      description: "Explore the ethical considerations and challenges in AI development and deployment.",
      difficulty: "Advanced"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white py-16">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-6">AI Basics</h1>
          <p className="text-xl text-gray-600">
            Begin your journey into artificial intelligence with foundational concepts and practical applications.
          </p>
        </div>
        <div className="grid md:grid-cols-2 gap-8 max-w-6xl mx-auto">
          {topics.map((topic) => (
            <TopicCard key={topic.title} {...topic} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AIBasics;
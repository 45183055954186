// src/services/api.js
import axios from 'axios';

// Get the base URL from environment variable
const BASE_URL = 'https://srv630820.hstgr.cloud/ai-engine';

// Create axios instance with default config
const api = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 10000 // 10 seconds timeout
});

// Add request interceptor for detailed logging
api.interceptors.request.use(
    config => {
        // Detailed request logging
        console.group('API Request Details');
        console.log('Full URL:', `${config.baseURL}${config.url}`);
        console.log('Method:', config.method.toUpperCase());
        console.log('Headers:', config.headers);
        console.log('Params:', config.params);
        console.groupEnd();
        return config;
    },
    error => {
        console.error('Request Error:', error);
        return Promise.reject(error);
    }
);

// Add response interceptor for error handling
api.interceptors.response.use(
    response => {
        // Log successful response
        console.group('API Response');
        console.log('Status:', response.status);
        console.log('Data:', response.data);
        console.groupEnd();
        return response.data;
    },
    error => {
        console.group('API Error');
        console.error('Status:', error.response?.status);
        console.error('Error:', error.response?.data?.error || error.message);
        console.error('Config:', error.config);
        console.groupEnd();
        return Promise.reject(error);
    }
);

// Blog related API calls
export const blogApi = {
    // Get all posts with optional pagination and filters
    getAllPosts: async (params = {}) => {
        const { page = 1, limit = 6 } = params;
        try {
            // Note: Removed the /api prefix since it's handled by the backend routes
            const response = await api.get('/api/blog', { 
                params: { 
                    page, 
                    limit,
                } 
            });
            return response;
        } catch (error) {
            console.error('Error fetching posts:', error);
            throw error;
        }
    },

    // Get single post by slug
    getPostBySlug: async (slug) => {
        try {
            if (!slug) throw new Error('Slug is required');
            const response = await api.get(`/api/blogs/${slug}`);
            return response;
        } catch (error) {
            console.error('Error fetching post:', error);
            throw error;
        }
    },

    // Get single post by id
    getPostById: async (id) => {
        try {
          if (!id) throw new Error('Post ID is required');
          const response = await api.get(`/api/blog/${id}`);
          return response;
        } catch (error) {
          console.error('Error fetching post:', error);
          throw error;
        }
      }
};

// Health check
export const healthCheck = () => api.get('/health');

export default api;
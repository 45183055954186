import React from 'react';

const TopicCard = ({ title, description, difficulty }) => (
  <div className="bg-white rounded-2xl p-8 hover:shadow-lg transition-all duration-300 border border-gray-100">
    <div className={`inline-block px-3 py-1 rounded-full text-sm font-medium mb-4 
      ${difficulty === 'Beginner' ? 'bg-green-50 text-green-600' : 
        difficulty === 'Intermediate' ? 'bg-blue-50 text-blue-600' :
        'bg-purple-50 text-purple-600'}`}
    >
      {difficulty}
    </div>
    <h3 className="text-xl font-semibold mb-3">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const DeepLearning = () => {
  const topics = [
    {
      title: "Neural Networks Fundamentals",
      description: "Learn the basic building blocks of neural networks and their architecture.",
      difficulty: "Beginner"
    },
    {
      title: "Convolutional Neural Networks",
      description: "Master CNNs for computer vision and image processing tasks.",
      difficulty: "Intermediate"
    },
    {
      title: "Recurrent Neural Networks",
      description: "Explore RNNs and their applications in sequential data processing.",
      difficulty: "Intermediate"
    },
    {
      title: "Transfer Learning",
      description: "Learn to leverage pre-trained models for your specific tasks.",
      difficulty: "Advanced"
    },
    {
      title: "Generative Models",
      description: "Understanding GANs and other generative deep learning approaches.",
      difficulty: "Advanced"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white py-16">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-6">Deep Learning</h1>
          <p className="text-xl text-gray-600">
            Explore the cutting-edge of AI through deep neural networks and their applications.
          </p>
        </div>
        <div className="grid md:grid-cols-2 gap-8 max-w-6xl mx-auto">
          {topics.map((topic) => (
            <TopicCard key={topic.title} {...topic} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DeepLearning;
// utils/useContentProtection.js
import { useEffect } from 'react';
import { protectionConfig } from '../config/protectionConfig';

export const useContentProtection = () => {
  useEffect(() => {
    // Only apply in production unless configured otherwise
    if (!protectionConfig.enableInProduction && process.env.NODE_ENV !== 'production') {
      return;
    }

    const handlers = {
      // Context menu (right click)
      handleContextMenu: (e) => {
        if (!protectionConfig.disableRightClick) return;
        if (shouldAllowForElement(e.target)) return;
        e.preventDefault();
        return false;
      },

      // Keyboard shortcuts
      handleKeyDown: (e) => {
        if (!protectionConfig.disableKeyboardCopy) return;
        if (shouldAllowForElement(e.target)) return;
        
        // Prevent common keyboard shortcuts
        if (e.ctrlKey || e.metaKey) {
          switch (e.key.toLowerCase()) {
            case 'c':
            case 'x':
            case 'u':
            case 'i':
            case 'p':
              e.preventDefault();
              return false;
            default:
              return true;
          }
        }
      },

      // Selection
      handleSelection: (e) => {
        if (shouldAllowForElement(e.target)) return;
        return false;
      }
    };

    // Helper to check if element should be excluded
    const shouldAllowForElement = (element) => {
      return protectionConfig.excludedSelectors.some(selector => 
        element.matches(selector)
      );
    };

    // Add event listeners
    Object.entries(handlers).forEach(([event, handler]) => {
      document.addEventListener(event.toLowerCase().replace('handle', ''), handler, { 
        passive: false 
      });
    });

    // Cleanup
    return () => {
      Object.entries(handlers).forEach(([event, handler]) => {
        document.removeEventListener(event.toLowerCase().replace('handle', ''), handler);
      });
    };
  }, []);
};
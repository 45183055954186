import React from 'react';

const TopicCard = ({ title, description, difficulty }) => (
  <div className="bg-white rounded-2xl p-8 hover:shadow-lg transition-all duration-300 border border-gray-100">
    <div className={`inline-block px-3 py-1 rounded-full text-sm font-medium mb-4 
      ${difficulty === 'Beginner' ? 'bg-green-50 text-green-600' : 
        difficulty === 'Intermediate' ? 'bg-blue-50 text-blue-600' :
        'bg-purple-50 text-purple-600'}`}
    >
      {difficulty}
    </div>
    <h3 className="text-xl font-semibold mb-3">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const MachineLearning = () => {
  const topics = [
    {
      title: "Supervised Learning",
      description: "Master classification and regression techniques with labeled data sets.",
      difficulty: "Beginner"
    },
    {
      title: "Unsupervised Learning",
      description: "Explore clustering and dimensionality reduction with unlabeled data.",
      difficulty: "Intermediate"
    },
    {
      title: "Feature Engineering",
      description: "Learn how to create and select the best features for your ML models.",
      difficulty: "Intermediate"
    },
    {
      title: "Model Evaluation",
      description: "Understanding metrics and validation techniques for ML models.",
      difficulty: "Intermediate"
    },
    {
      title: "Ensemble Methods",
      description: "Combine multiple models to improve prediction accuracy.",
      difficulty: "Advanced"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white py-16">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-6">Machine Learning</h1>
          <p className="text-xl text-gray-600">
            Discover how machines learn from data and make predictions through various algorithms and techniques.
          </p>
        </div>
        <div className="grid md:grid-cols-2 gap-8 max-w-6xl mx-auto">
          {topics.map((topic) => (
            <TopicCard key={topic.title} {...topic} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MachineLearning;